:root {
  scroll-behavior: smooth;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  min-width: 320px;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.recurly-element,
.recurly-hosted-field {
  height: 3.125rem;
  border-radius: 0.25rem;
}

/* react-transition-group styles and transitions */
.container {
  position: relative;
  width: 100%;
}

.page {
  position: absolute;
  width: 100%;
}

.page-enter {
  opacity: 0;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  -o-transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.page-exit {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  -o-transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.slick-dots > li {
  margin: 0;
}

.slick-dots li button::before {
  font-size: 0.625rem;
}
.reviews .slick-dots > .slick-active button:before {
  color: white !important;
}

.plans .slick-dots > .slick-active button:before {
  color: white !important;
}

.slick-dots .slick-active button::before {
  font-size: 0.75rem;
}

/* override slick arrow styles */
.slick-arrow {
  display: none !important;
}

.reasons-container .slick-initialized .slick-slide > div {
  margin: 0 0.5rem;
}

.plans .slick-initialized .slick-slide > div {
  margin: 0 0.5rem;
}

.reviews .slick-dots > .slick-active button:before {
  color: black !important;
}

@media screen and (max-width:768px) {
  .reasons-container .slick-list {
    padding: 0px 40px !important;
  }
}
