.mobile-nav {
  padding-top: 2rem;
  top: 3.75rem;
  background: white;
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 3.8rem);
  border-top: 1px solid #cbd5e0;
  opacity: 0;
  overflow: auto;
  display: none;
}

@media screen and (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}

.mobile-nav-enter {
  display: block;
  opacity: 1;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

@media screen and (min-width: 768px) {
  .mobile-nav-enter {
    display: none;
  }
}

.mobile-nav-enter-active {
  display: block;
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  -o-transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

@media screen and (min-width: 768px) {
  .mobile-nav-enter-active {
    display: none;
  }
}

.mobile-nav-active {
  opacity: 1;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.mobile-nav-enter-done {
  display: block;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .mobile-nav-enter-done {
    display: none;
  }
}

.mobile-nav-exit-done {
  opacity: 0;
  display: none;
}
